import ApiService from "@/core/services/api.service";
import { INITIAL_SNACKBAR_INFO } from "@/view/components/SnackBarInfoComponent.vue";

export const listModelsMixins = {
  data() {
    return {
      loadingTable: false,
      snackbarInfo: INITIAL_SNACKBAR_INFO,
    };
  },
  methods: {
    editItem(id) {
      this.$nextTick(() => {
        this.modalData.editedId = id;
        this.modalData.dialog = true;
      });
    },

    handleNew() {
      this.modalData.editedId = null;
      this.modalData.dialog = true;
    },

    handleSaveModalForm(item = null) {
      // this.handleCloseModalForm();
      if (item) {
        this.handleClickItem(item);
      }
    },

    handleClickItem(item) {
      if (this.permissionCan("view") && !this.dialogDelete && item.id) {       
        this.$router.push(this.routePath + item.id);
      }
    },

    handleCloseModalForm() {
      this.modalData.dialog = false;
      this.fetchModel();
      if (
        this.$router.currentRoute.path.replaceAll("/", "") !=
        this.routePath.replaceAll("/", "")
      )
        this.$router.push(this.routePath);
    },

    deleteItem(item) {
      this.editedItem = Object.assign({}, item);
      this.dialogDelete = true;
    },

    handleDeleteItemConfirm() {
      ApiService.delete(this.endPoint + this.editedItem.id)
        .then(() => {
          this.fetchModel();
        })
        .catch((error) => {
          console.log("Error!: ", error);
        });
      this.handleCloseDelete();
    },

    handleCloseDelete() {
      this.modalData.editedId = null;
      this.dialogDelete = false;
    },

    permissionCan(action) {
      return this.permissions.find((x) => x.name == this.permissionTo + action);
    },

    showSnackBarInfo() {
      let snackbarInfo = this.snackbarInfo;

      snackbarInfo.active = true;

      this.snackbarInfo = snackbarInfo;
    },

    getDatatableFooterProps() {
        return {'items-per-page-options': [50, 75, 100]};
    },
    
    getDatatableItemPerPage() {
        return 50;
    }
  },
};
